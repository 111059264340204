import moment from 'moment/moment';
import cheerio from 'cheerio';
import Json2Csv from 'json2csv';

const Json2csvParser = Json2Csv.Parser;

export const extractProductsFromHtml = (html) => {
  // Explicit use RegExp to prevent Babel bug when parsing /.../
  const productDetailRegEx = new RegExp('(?:var pliAttributes = )({.+?})(?:;)', 'gs');
  const products = {};
  const csvProducts = [];
  // Add product details from json array
  let match = productDetailRegEx.exec(html);
  while (match) {
    const product = JSON.parse(match[1]);
    product.sizesString = product.product_size.join(',');
    // Beautify
    const beautifiedProduct = { ...product };
    beautifiedProduct.online_exclusive = product.online_exclusive[0] === 'true';
    [beautifiedProduct.product_category] = product.product_category;
    [beautifiedProduct.product_subcategory] = product.product_subcategory;
    [beautifiedProduct.product_page_category] = product.product_page_category;
    [beautifiedProduct.product_primary_category] = product.product_primary_category;
    beautifiedProduct.product_collection = product.product_collection
    && product.product_collection.length > 0 ? product.product_collection[0] : '';
    beautifiedProduct.product_color = product.product_color && product.product_color.length > 0
      ? product.product_color[0] : '';
    beautifiedProduct.product_delivery_group = product.product_delivery_group
    && product.product_delivery_group.length > 0
      ? product.product_delivery_group[0] : '';
    [beautifiedProduct.product_department] = product.product_department;
    [beautifiedProduct.product_gender] = product.product_gender;
    [beautifiedProduct.product_id] = product.product_id;
    [beautifiedProduct.product_sku] = product.product_sku;
    [beautifiedProduct.product_name] = product.product_name;
    beautifiedProduct.product_price = parseFloat(product.product_price);
    product.product_season = product.product_season && product.product_season.length > 0
      ? product.product_season[0] : '';
    [beautifiedProduct.product_size] = product.product_size;
    [beautifiedProduct.product_style] = product.product_style;
    if (!beautifiedProduct.product_style)
      beautifiedProduct.product_style = beautifiedProduct.product_id.substring(2);
    beautifiedProduct.product_msrp = parseFloat(product.product_msrp[0]);
    [beautifiedProduct.product_ecommerce_date] = product.product_ecommerce_date;
    [beautifiedProduct.sbu] = product.sbu;
    if (product.bv_avg_rating && product.bv_avg_rating.length > 0) {
      beautifiedProduct.bv_avg_rating = parseFloat(
        product.bv_avg_rating[0],
      );
    }
    if (product.bv_reviews && product.bv_reviews.length > 0) {
      beautifiedProduct.bv_reviews = parseInt(
        product.bv_reviews[0], 10,
      );
    }
    [beautifiedProduct.product_brand] = product.product_brand;
    [beautifiedProduct.product_class] = product.product_class;
    [beautifiedProduct.product_subclass] = product.product_subclass;
    [beautifiedProduct.product_size] = product.product_size;
    beautifiedProduct.product_season = product.product_season && product.product_season.length > 0
      ? product.product_season[0] : '';
    [beautifiedProduct.product_size] = product.product_size;

    if (!products[product.product_id[0]]) {
      products[product.product_id[0]] = beautifiedProduct;
      csvProducts.push(beautifiedProduct);
    }
    match = productDetailRegEx.exec(html);
  }

  // Add detail from product html block
  const $ = cheerio.load(html);
  $('ul.row li div.product').each((index, element) => {
    // Get item info
    const jsonInfo = $(element).html().match(new RegExp('(?:var pliAttributes = )({.+?})(?:;)',
      's'));
    if (!jsonInfo || !jsonInfo[1])
      return;
    const product = JSON.parse(jsonInfo[1]);
    // Get sizes
    // const sizesSelect = $(element).find('select#va-size');
    // Ignore crossSellBranding products
    // if (!sizesSelect.attr('name')) return;
    // const productName = sizesSelect.attr('name').substr(6, sizesSelect.attr('name').length - 11);
    //console.log(index, productName);
    const productName = product.product_id;
    // const sizes = [];
    // sizesSelect.find('option').each((findex, felement) => {
    //   if (findex > 0) sizes.push($(felement).text().trim());
    // });
    // products[productName].sizes = sizes;
    // products[productName].sizes = product.product_size;
    // products[productName].sizesString = sizes.join(',');

    // Get image link
    // data-yo-src
    const imglink =
      $(element)
        .find('img.tile-image')
        .attr(
          'data-yo-src',
        ) ||
      $(element)
      .find('img.shop-now-image')
      .attr(
        'data-srcset',
      ) || $(element)
      .find('img.shop-now-image')
      .attr(
        'srcset',
      ) || $(element)
      .find('img.tile-image')
      .attr(
        'src',
      );

    //console.log(imglink);

    [products[productName].imageUrl] = new RegExp('.+?\\.(jpg|gif|webp)').exec(imglink);

    // console.log($(element)
    //   .find('img.shop-now-image')
    //   .attr(
    //     'srcset',
    //   ));

    // Promotions
    products[productName].promotions = [];
    $(element).find('div.promotion-callout').each((pindex, pelement) => {
      products[productName].promotions.push($(pelement).text().trim());
    });

    // Discounted price
    // products[productName].product_price = parseFloat(
    //   $(element).find('.product-pricing span').first().text()
    //     .trim()
    //     .substr(1),
    // );
  });
  return csvProducts;
};

export const convertProductsToCsv = (products, priceMultiplier, usdRate, pieceWage, brandName) => {
  const json2csvParser = new Json2csvParser();
  // Prevent setting attachment header, if there is an exception in csvParser
  return json2csvParser.parse(products.map((product) => {
    const wProduct = { ...product };
    wProduct.downloadPs = `Invoke-WebRequest -Uri "${product.imageUrl}" -OutFile "${product.product_style}.jpg"`;
    const todayString = moment().format('DD/MM/YY');
    const priceInK = Math.ceil((
      product.product_price
      * priceMultiplier
      * usdRate
      + pieceWage * 1) / 1000);
    wProduct.watermark = `$ps=(magick identify -format %W ${product.product_style}.jpg)/24;`
      + 'magick convert -gravity SouthEast -pointsize $ps -fill Black'
      + ' -annotate +$($ps-1)+$($ps-1) %t -fill White -annotate +$($ps+1)+$($ps+1) %t -fill Orange -annotate +$ps+$ps %t'
      + ` -fill Black -annotate +$($ps-1)+$(2*$ps-1) "${todayString}"`
      + ` -fill White -annotate +$($ps+1)+$(2*$ps+1) "${todayString}"`
      + ` -fill Orange -annotate +$ps+$(2*$ps) "${todayString}"`
      + ' -gravity NorthWest -fill Black -annotate +$($ps-1)+$($ps-1) "beong.vn" -fill White -annotate'
      + ' +$($ps+1)+$($ps+1) "beong.vn" -fill Orange -annotate +$ps+$ps "beong.vn"'
      + ` -gravity NorthEast -fill Black -annotate +$($ps-1)+$($ps-1) "${brandName}"`
      + ` -fill White -annotate +$($ps+1)+$($ps+1) "${brandName}"`
      + ` -fill Orange -annotate +$ps+$ps "${brandName}"`
      + ` -gravity SouthWest -fill Black -annotate +$($ps-1)+$($ps-1) "Size ${product.sizesString}"`
      + ` -fill White -annotate +$($ps+1)+$($ps+1) "Size ${product.sizesString}"`
      + ` -fill Orange -annotate +$ps+$ps "Size ${product.sizesString}"`
      + ` -fill Black -annotate +$($ps-1)+$(2*$ps-1) "${priceInK}k + cân"`
      + ` -fill White -annotate +$($ps+1)+$(2*$ps+1) "${priceInK}k + cân"`
      + ` -fill Blue -annotate +$ps+$(2*$ps) "${priceInK}k + cân"`
      + ` ${product.product_style}.jpg .\\watermark\\${product.product_style}.jpg`;
    return wProduct;
  }));
};
