import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import './App.css';
import env from './config';
import { convertProductsToCsv, extractProductsFromHtml } from './utils/CarterUtils';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cartersUrl: 'https://www.carters.com/carters-baby-girl-clearance?sz=all',
      brandName: 'Carters',
      priceMultiplier: 0.872,
      pieceWage: 50000,
      usdRate: 24162,
      droppedFiles: [],
    }
  }

  onFileDropped = (acceptedFiles) => {
    this.setState({ droppedFiles: acceptedFiles });
    console.log(acceptedFiles)
  }

  render() {
    const { droppedFiles, priceMultiplier, brandName, pieceWage, usdRate } = this.state;
    return (
      <div className="App">
        <header className="App-header">
          <table className="wrapper">
            <colgroup>
              <col className="scrap-first-col" />
              <col />
            </colgroup>
            <tbody>
            <tr>
              <td>
                Download html
              </td>
              <td>
              <textarea className="take-remaining-width" rows={4}>(() =>&#123;const element = document.createElement("a");
const csvFile = new Blob([document.documentElement.innerHTML],&#123;type: 'text/html'&#125;);
element.href = URL.createObjectURL(csvFile);
element.download = `$&#123;document.title&#125;.html`;
element.click()&#125;)();
              </textarea>
              </td>
            </tr>
            <tr>
              <td>
                Drop zone
              </td>
              <td>
                <Dropzone onDrop={this.onFileDropped}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        {droppedFiles && droppedFiles.length > 0 ?
                          <div>{droppedFiles.map((file, idx) =>
                            <div key={idx}>{file.name}</div>)}
                            <div>
                              <button onClick={() => {this.setState({ droppedFiles: [] })}}>Clear</button>
                            </div>
                          </div> :
                          <p>Drag 'n' drop some files here, or click to select files</p>}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </td>
            </tr>
            {!(droppedFiles && droppedFiles.length > 0) &&
            <tr>
              <td>URL</td>
              <td><input
                className="take-remaining-width"
                name="cartersUrl"
                value={this.state.cartersUrl}
                onChange={(value) => this.onInputTextChanged(value)}
              /></td>
            </tr>}
            <tr>
              <td>Brand</td>
              <td><input
                name="brandName"
                className="take-remaining-width"
                value={this.state.brandName}
                onChange={(value) => this.onInputTextChanged(value)}
              /></td>
            </tr>
            <tr>
              <td>Price Multiplier</td>
              <td><input
                name="priceMultiplier"
                className="take-remaining-width"
                value={this.state.priceMultiplier}
                onChange={(value) => this.onInputTextChanged(value)}
              /></td>
            </tr>
            <tr>
              <td>Piece wage(VND)</td>
              <td><input
                name="pieceWage"
                className="take-remaining-width"
                value={this.state.pieceWage}
                onChange={(value) => this.onInputTextChanged(value)}
              /></td>
            </tr>
            <tr>
              <td>USD rate</td>
              <td><input
                name="usdRate"
                className="take-remaining-width"
                value={this.state.usdRate}
                onChange={(value) => this.onInputTextChanged(value)}
              /></td>
            </tr>
            </tbody>
          </table>


          <button onClick={() => this.onScrapingClicked()}>Scraping</button>
        </header>
      </div>
    );
  }

  onInputTextChanged(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  onScrapingClicked() {
    const { droppedFiles, priceMultiplier, brandName, pieceWage, usdRate } = this.state;
    if (!droppedFiles || droppedFiles.length <= 0)
    // Use begongApi
      window.open(`${env.API_BASE_URL}/carters/products?format=csv&url=${encodeURI(
        this.state.cartersUrl)}&brandName=${this.state.brandName}&priceMultiplier=${this.state.priceMultiplier}&pieceWage=${this.state.pieceWage}&usdRate=${this.state.usdRate}`,
        "_blank");
    else {
      // Extract from uploaded files
      droppedFiles.forEach((droppedFile) => {
        const reader = new FileReader();
        reader.onload = () => {
          const element = document.createElement("a");
          const csvFile = new Blob([convertProductsToCsv(extractProductsFromHtml(reader.result),
            priceMultiplier,
            usdRate,
            pieceWage,
            brandName)],
            { type: 'text/csv' });
          element.href = URL.createObjectURL(csvFile);
          element.download = `${droppedFile.name}.csv`;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        };
        console.log(droppedFile.name);
        reader.readAsText(droppedFile);
      });
    }
  }
}

export default App;
